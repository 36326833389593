// Define a type for the parameters passed to the action
type ClickOutsideParams = {
    enabled: boolean;
    callback: () => void;
};

export function clickOutside(node: HTMLElement, params: ClickOutsideParams) {
    const handleOutsideClick = (event: MouseEvent) => {
        if (!node.contains(event.target as Node)) {
            params.callback();
        }
    };

    function update({ enabled }: ClickOutsideParams) {
        if (enabled) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }
    }

    update(params);

    return {
        update,
        destroy() {
            document.removeEventListener('click', handleOutsideClick);
        }
    };
}
