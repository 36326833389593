<script lang="ts">
</script>

<div class="podium">
    <div class="silver pdm">
        <div class="time">30.4</div>
    </div>
    <div class="gold pdm">
        <div class="time">20.5</div>
    </div>
    <div class="bronze pdm">
        <div class="time">40.5</div>
    </div>
</div>

<style>
    .podium {
        display: flex;
        position: relative;
        flex-direction: row;
        color: black;
        margin-top: 30px;
        margin-left: 2%;
    }
    .silver {
        background: linear-gradient(#fffcfc, #c2c2c2, #848484, #969090, #e9e9e9);
        height: 40px;
        margin-top: 35px;
    }
    .gold {
        background: linear-gradient(#ffff95, #f3c334, #d2a728, #ffff95);
        height: 60px;
        margin-top: 15px;
    }
    .bronze {
        background: linear-gradient(#ffeaea, #e8b094, #d67650, #da8f69, #ffe8e8);
        height: 25px;
        margin-top: 50px;
    }
    .pdm {
        display: flex;
        width: 20px;
        justify-content: center;
        align-items: center;
    }
    .time {
        font-size: 9px;
    }
</style>
