<script lang="ts">
    import { navigate } from "svelte-routing";
    import { toast } from "@zerodevx/svelte-toast";
    import { onMount, onDestroy } from "svelte";
    import { fetchGet, fetchPost } from "./services/fetch";
    import type { SvelteComponent } from "svelte";
    //import { newEvent } from "./services/stores";
    //import { updateRating } from "./services/Contact";
    import { AppliedQuestionClientVm } from "./Appliedmath/AppliedQuestionVm";
    //import AppliedImage from "./AppliedImage.svelte";

    let questionsVm: AppliedQuestionClientVm[] = [];

    async function load() {
        const clientUrl = `/AppliedMath/GetProblems`;
        questionsVm = await fetchGet<AppliedQuestionClientVm[]>(clientUrl);
    }

    onMount(() => {
        load();
    });
</script>

<div class="content">
    <p>
        <input type="checkbox"/>Show all applied math problems
    </p>
    <div class="table">
        <div class="header cell">Username</div>
        <div class="header cell">charid</div>
        <div class="header cell">Title</div>
        <div class="header cell">Created_At</div>
        <div class="header cell">Suggested_Rating</div>
        <div class="header cell">Calculated_Rating</div>
        <div class="header cell">StepCount</div>
        <div class="header cell">Times solved</div>
        <div class="header cell">No. of Correct</div>
        <div class="header cell">No. of Wrong</div>

        {#each questionsVm as question}
            <div class="row">
                <div class="cell">{question.user}</div>
                <div class="cell"><a class="link" href="applied/{question.charid}">{question.charid}</a></div>
                <div class="cell"><a class="link" href="applied/{question.charid}">{question.title}</a></div>
                <div class="cell">{question.created_date}</div>
                <div class="cell">{question.suggested_rating}</div>
                <div class="cell">{question.calculated_rating}</div>
                <div class="cell">{question.stepCount}</div>
                <div class="cell">{question.solved_times}</div>
                <div class="cell">{question.correct}</div>
                <div class="cell">{question.wrong}</div>
            </div>
        {/each}
    </div>
</div>

<style>
    .table {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        gap: 10px;
    }
    .cell {
        padding: 5px;
        border: 1px solid #ccc;
    }
    .header {
        font-weight: bold;
    }
    .row {
        display: contents;
    }
    .link {
        color: white;
        text-decoration: underline;
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
</style>
