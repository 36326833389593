<script lang="ts">
    export let onContinue: () => void;
    import { onMount, onDestroy } from "svelte";

    function handleKeydown(event: KeyboardEvent) {
        if (event.key === "Enter") {
            onContinue();
        }
    }

    onMount(() => {
        document.addEventListener("keydown", handleKeydown);
    });

    onDestroy(() => {
        document.removeEventListener("keydown", handleKeydown);
    });
</script>

<div class=" info-window">
    <div class="subheading">Applied Intro</div>
    <!-- <img alt="icon" src="/homepage.png" style="" width="60px" height="60px" /> -->
    <div class="smalltext">
        Applied Math Path: Put your knowledge to the test with real-world problems solved on paper, presented in a multiple-choice format. From calculating the
        area of complex shapes to solving intricate algebra problems, this path translates theoretical knowledge into practical skills. 
    </div>
    <button on:click={onContinue} class="continue-btn" > Continue</button>
</div>

<style>
    .subheading {
        font-size: 13px;
    }
    .smalltext {
        color: white;
        padding-top: 10px;
    }
    .info-window {
        position: absolute;
        background-color: black;
        color: gold;
        padding: 10px;
        margin-left: 10px;
        font-size: 10px;
        width: 300px;
        height: 150px;
        border: 2px solid yellow;
        border-radius: 5px;
        left: 80px;
        top: 400px;
        display: block;
        z-index: 9999;
    }
    .continue-btn {
        width: 60px;
        height: 20px;
        font-size: 11px;
        top: 40px;
        left: -200px;
    }
    .continue-btn:hover {
        color: white;
        animation: glow-border 0.8s infinite alternate;
    }
    @keyframes glow-border {
        0% {
            border-color: gold;
            box-shadow: 0 0 5px gold;
        }
        100% {
            border-color: white;
            box-shadow: 0 0 10px gold;
        }
    }
</style>
