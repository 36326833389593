<script lang="ts">
    import { navigate, Link } from "svelte-routing";
    import { UserService } from "./services/user.service";
    import { toast } from "@zerodevx/svelte-toast";
    import { rating, userName, isAuthenticated } from "./services/stores";
    import { updateRating} from "./services/Contact"
    //When we relaod, this is used to set isAuthenicated/username
    let loggedIn = UserService.isLoggedIn(); 
    isAuthenticated.set(loggedIn);

    userName.set( UserService.getDisplayName() );  

    //$: console.log("loggedIn (Reactive): " + loggedIn);

    //I think that $isAuthenticated is lost if we reload
    updateRating(); //for when reloading

    console.log("Profile");

    $: if ($userName) {
        console.log("Profile, username:" + $userName);
    }

    $: if ($isAuthenticated) {
        console.log("Profile, authenticated username:" + $userName);
    } else {
        console.log("Profile, NOT authenticated username:" + $userName);
    }



    // let userName = UserService.getDisplayName();

    // $: userName = UserService.getDisplayName();//Don't think this will work

    function logout() {
        let userService = new UserService();
        userService.logout();
        //loggedIn = UserService.isLoggedIn();
        navigate("/");
        toast.push("See you next time", {
            classes: ["success"],
            pausable: true,
        });
        //loggedIn = UserService.isLoggedIn();
        isAuthenticated.set(false);

        console.log("logged in: " + isAuthenticated);
    }
</script>

{#if $isAuthenticated}
    <div class="flex1">
        <p class="rating">{$rating}</p>
        <button
            class="username-button"
            id="headlessui-menu-button-:rbe:"
            type="button"
            aria-haspopup="true"
            aria-expanded="false"
            data-headlessui-state=""
        >
            {$userName}
            <div class="flex-shrink-0">
                <div class="items-center">
                    <!-- <img
                            alt="User"
                            loading="lazy"
                            width="32"
                            height="32"
                            decoding="async"
                            data-nimg="1"
                            class="rounded-sm"
                            src="https://api.adorable.io/avatars/[200]/[identifier]/yellow.png"
                            style="color: transparent;"
                        /> -->
                </div>
            </div>
            <!-- <div class="option">Logout
                <button on:click={() => logout()} class="dropdown"></button>
            </div> -->
        </button>
    </div>
{:else}
    <!-- <h1>DEBUG NOT logged in DEBUG</h1> -->
{/if}

<style>
    .rating {
        display: flex;
        margin-top: 10px;
        color: palegoldenrod;
        background: none;
        width: 100px;
        height: 30px;
        font-weight: bold;
        cursor: pointer;
        justify-content: center;
        align-items: center;
    }
    .username-button {
        display: flex;
        color: yellow;
        margin-top: 10px;
        background: none;
        width: 100px;
        height: 30px;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        border: none;
    }
    .username-button:hover {
        display: block;
    }
    .flex1 {
        display: flex;
        flex-direction: row;
        z-index: 6;
        position: absolute;
        top: 10px;
        right: 10px;
    }
    /* .option {
        display: none;
        position: absolute;
        background-color: white;
        color: black;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 30px;
        margin-top: 10px;
        right: 5px;
        font-size: 15px;
        z-index: 5;
        cursor: pointer;
    }

    .dropdown {
        display: none;
    }
    .flex1:hover .option {
        display: flex;
    } */
</style>
