<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import FibonacciSpinner from "./loader/FibonacciSpinner.svelte";
</script>

<h1>F2</h1>
It would help the put the first box exactly in the middle, then we could use more
of the screenF It is important to not for the first, to go right, then draw, because
we draw to the right also Then the first box would be outside on the first draw,
and nothing works after that, bc we reuse everything, all that is drawn

<div class="overlay">
  <div class="overlay-canva" style="width: 300px; height: 300px;">
    <FibonacciSpinner canvasid="f200" />
  </div>
</div>

<style>
  /* div {
    background-color: #f0f0f0; /* Light grey background  for debug */
  .overlay-canva {
    position: fixed;
    top: 20%;
  }
  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.779); 
    z-index: 999; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
