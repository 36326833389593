import { authHeader } from "./auth-header";
import { Errorhandler } from "./Errorhandler";
import { toast } from "@zerodevx/svelte-toast";
import { connectionTrouble } from './stores'; // Adjust path as necessary
import { isLoading } from './stores'; // Adjust path as necessary //maybe we should count up/down, if we are fetching multiple places


//it is okay to return a promise, even if it is alleady resolved. That way we can await it. 
export async function fetchGet<T>(url: string): Promise<T> {

    connectionTrouble.set(false); //being optimistic


    let apiUrl = import.meta.env.VITE_API;

    var envUrl = apiUrl + url;
    if (url[0] != "/")
        var envUrl = apiUrl + "/" + url;


    const requestOptions = {
        method: "GET",
        headers: { ...authHeader(), "Content-Type": "application/json" },
    };

    try {
        isLoading.set(true);
        const response = await fetch(envUrl, requestOptions);
        if (!Errorhandler.HandleError(response)) {

            //if the expected type is 'void', we won't do response.json
            //if (typeof response.ok !== 'undefined' && response.ok) { not working
            if (response.status === 204 || response.headers.get("Content-Length") === "0") {
                return undefined as unknown as T;
            } else {
                let result = await response.json();
                return result as T;
            }
        } else {
            throw new Error("Request failed " + url);
        }
    } catch (error) {
        if (error instanceof TypeError && error.message === "Failed to fetch") {
            // Handle network errors (like server not running)
            toast.push("Cannot connect to the server. Please check your network connection or try again later.", {
                classes: ["error"],
                dismissable: true, // Adds a close button
                initial: 0,
            });

            // Inside catch block of fetchGet function

            // When catching network errors
            connectionTrouble.set(true);

        } else {
            // Handle other types of errors
            toast.push("Error in fetchGet: " + error, {
                classes: ["error"],
                dismissable: true, // Adds a close button
                initial: 0,
            });
        }
        throw error; // Rethrow the error for further handling if needed
    }
    finally {
        isLoading.set(false);
    }

}

// ... other imports

export async function fetchPost<ReturnType, SendType>(url: string, data: SendType): Promise<ReturnType> {
    let apiUrl = import.meta.env.VITE_API;

    var envUrl = apiUrl + url;
    if (url[0] != "/")
        var envUrl = apiUrl + "/" + url;

    const requestOptions = {
        method: "Post",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data), // SendType data is stringified and sent in the request body
    };

    try {
        const response = await fetch(envUrl, requestOptions);
        if (!Errorhandler.HandleError(response)) {
            if (response.status === 204 || response.headers.get("Content-Length") === "0") {
                return undefined as unknown as ReturnType;
            } else {
                let result = await response.json();
                return result as ReturnType;
            }
        } else {
            throw new Error("Request failed " + url);
        }
    } catch (error) {
        if (error instanceof TypeError && error.message === "Failed to fetch") {
            // Handle network errors (like server not running)
            toast.push("Cannot connect to the server. Please check your network connection or try again later.", {
                classes: ["error"],
                initial: 0,
                dismissable: true, // Adds a close button
            });

            // Inside catch block of fetchGet function

            // When catching network errors
            connectionTrouble.set(true);

        } else {
            // Handle other types of errors
            toast.push("Error in fetchPost: " + error, {
                classes: ["error"],
                initial: 0,
                dismissable: true, // Adds a close button
            });
        }
        throw error; // Rethrow the error for further handling if needed
    }
}


