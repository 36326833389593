<script lang="ts">
    import Podium from "./Podium.svelte";
    export let title;
    export let colorval;
    export let text;

    let isGold=false;
    let isSilver=false;
    let isBronze=false;
    $: if(colorval=="gold") {
        isGold=true;
        console.log("gggggold")
    }
    $: if(colorval=="silver") {
        isSilver=true;
        console.log("ssssilver")

    }
    $: if(colorval=="bronze") {
        isBronze=true;
        console.log("bbbbbronze")

    }
</script>

<div class="achievements">
    <div class="achievement">

        {#if isGold}
        <div class="gold clr">
            <img alt="icon" src="/puzzle.svg" style="" width=60px height="60px">
            
        </div>
        {/if}
        {#if isSilver}
        <div class="silver clr">
            <img alt="icon" src="/puzzle.svg" style="" width=60px height="60px">
        </div>
        {/if}
        {#if isBronze}
        <div class="bronze clr">
            <img alt="icon" src="/puzzle.svg" style="" width=60px height="60px">
        </div>
        {/if}
        <div class="text">
            <div class="heading">{title}</div>
            <div class="child-text txt">{text}</div>
            <div >colorval:{colorval}</div>
        </div>
    </div>
</div>
<style>
    .achievements {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
    }
    .achievement {
        display: flex;
        position: relative;
        margin: 10px;
        color: white;
        width: 370px;
        height: 100px;
        background: white;
        border-radius: 5px;
        justify-content: start;
    }
    .text {
        display: flex;
        flex-direction: column;
    }
    .heading {
        margin-top: 10px;
        font-weight: bold;
        color: black;
    }
    .child-text {
        color: black;
        font-size: 13px;
        text-align: left;
    }
    .gold {
        background: linear-gradient(#ffd782, #f3c334, #eabc32, #ffff95);
    }
    .silver {
        background: linear-gradient(
            #fffcfc,
            #c2c2c2,
            #848484,
            #969090,
            #e9e9e9
        );
    }
    .bronze {
        background: linear-gradient(
            #ffeaea,
            #e8b094,
            #d67650,
            #da8f69,
            #ffe8e8
        );
    }
    
    .clr {
        width: 70px;
        height: 70px;
        margin: 15px;
        border: 1px solid rgb(208, 208, 208);
        justify-content: center;
        align-items: center;
    }
    img{
        margin: 5px;
    }
</style>
 <!-- <script>
    import "./Funnel.css";
    let repeatNext1min = 0;
    let repeatNext2min = 0;
    let repeatNext5min = 0;
    let repeatNext10min = 0;
    let repeatNext30min = 0;
    let repeatNext1H = 0;
    let repeatNext1D = 0;
    let repeatNext1W = 0;
    let repeatNext1M = 0;
    let inLongTermMemory= 0;
</script>

<div class="repeat">
    <img alt="icon" src="/funnel.svg" class="funnel" style="" />
    <div style="margin-top:-33px" class="time longterm">
        <div>{inLongTermMemory}</div>
        <p>longTermMemory</p>
    </div>
    <div style="margin-top:-25px" class="time one-month">
        <p>repeatNext1M</p>
        <div style="font-size: 10px;">{repeatNext1M}</div>
    </div>
    <div style="margin-top:-23px" class="time one-week">
        <div style="font-size: 10px;">{repeatNext1W}</div>
        <p>repeatNext1W</p>
    </div>
    <div style="margin-top:-18px" class="time one-day">
        <p>repeatNext1D</p>
        <div style="font-size: 10px;">{repeatNext1D}</div>
    </div>
    <div style="margin-top:-11px" class="time one-hour">
        <div style="font-size: 10px;">{repeatNext1H}</div>
        <p>repeatNext1H</p>
    </div>
    <div style="margin-top:-5px" class="time thirty-min">
        <p>repeatNext30min</p>
        <div style="font-size: 10px;">{repeatNext30min}</div>
    </div>
    
    <div style="margin-top:1px" class="time ten-min">
        <div style="font-size: 10px;">{repeatNext10min}</div>
        <p>repeatNext10minutes</p>
    </div>
    <div style="margin-top:8px" class="time five-min">
        <p>repeatNext5minutes</p>
        <div style="font-size: 10px;">{repeatNext5min}</div>
    </div>

    <div style="margin-top:18px" class="time two-min">
        <div style="font-size: 10px;">{repeatNext2min}</div>
        <p>repeatNext2minutes</p>
    </div>
    <div style="margin-top:30px" class="time one-min">
        <p>repeatNext1minute</p>
        <div style="font-size: 10px;">{repeatNext1min}</div>
    </div>

</div> -->
