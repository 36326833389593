<script>
	import { navigate, useLocation } from "svelte-routing";
	import { onMount } from "svelte";
	const location = useLocation();
	const user = localStorage.getItem("user");
	onMount(() => {
		if (!user) {
			localStorage.setItem("redirectAfterLogin", $location.pathname);
			navigate("/login");
		}
	});
</script>

<!-- without the if check, the child component, e.g the keypad is started to render for a moment. It failed on a fetch btw -->
{#if user}
  <slot />
{/if}