<script lang="ts">
    import { onMount } from "svelte";
    import { fetchGet, fetchPost } from "./services/fetch";

    let monday = 0; // This variable holds the state for Monday's training count
    let tuesday = 0; // This variable holds the state for Monday's training count
    let wednesday = 0; // This variable holds the state for Monday's training count
    let thursday = 0; // This variable holds the state for Monday's training count
    let friday = 0; // This variable holds the state for Monday's training count
    let saturday = 0; // This variable holds the state for Monday's training count
    let sunday = 0; // This variable holds the state for Monday's training count

    let mondaySpare = false; // Represents the state of the first checkbox for Monday
    let mondayStrike = false; // Represents the state of the second checkbox for Monday

    let tuesdaySpare = false; // Represents the state of the first checkbox for Monday
    let tuesdayStrike = false; // Represents the state of the second checkbox for Monday

    let wednesdaySpare = false; // Represents the state of the first checkbox for Monday
    let wednesdayStrike = false; // Represents the state of the second checkbox for Monday

    let thursdaySpare = false; // Represents the state of the first checkbox for Monday
    let thursdayStrike = false; // Represents the state of the second checkbox for Monday

    let fridaySpare = false; // Represents the state of the first checkbox for Monday
    let fridayStrike = false; // Represents the state of the second checkbox for Monday

    let saturdaySpare = false; // Represents the state of the first checkbox for Monday
    let saturdayStrike = false; // Represents the state of the second checkbox for Monday

    let sundaySpare = false; // Represents the state of the first checkbox for Monday
    let sundayStrike = false; // Represents the state of the second checkbox for Monday

    // Simulate fetching data on component mount
    onMount(async () => {
        load();
    });

    async function load() {
        var clientUrl = "/Strike/GetStrike/";
        // Simulate fetching a week's data, e.g., "210" for Monday=2, Tuesday=1, Wednesday=0, etc.
        let weekData = await fetchGet<string>(clientUrl); // Assume this function fetches the week's training data
        monday = parseInt(weekData.charAt(0));
        tuesday = parseInt(weekData.charAt(1));
        wednesday = parseInt(weekData.charAt(2));
        thursday = parseInt(weekData.charAt(3));
        friday = parseInt(weekData.charAt(4));
        saturday = parseInt(weekData.charAt(5));
        sunday = parseInt(weekData.charAt(6));

        updateCheckboxStates();
    }

    function updateCheckboxStates() {
        mondaySpare = monday >= 1;
        mondayStrike = monday >= 2;

        tuesdaySpare = tuesday >= 1;
        tuesdayStrike = tuesday >= 2;

        wednesdaySpare = wednesday >= 1;
        wednesdayStrike = wednesday >= 2;

        thursdaySpare = thursday >= 1;
        thursdayStrike = thursday >= 2;

        fridaySpare = friday >= 1;
        fridayStrike = friday >= 2;

        saturdaySpare = saturday >= 1;
        saturdayStrike = saturday >= 2;

        sundaySpare = sunday >= 1;
        sundayStrike = sunday >= 2;
    }

    // Update Monday's value based on checkboxes' states
    async function updateDayValue() {
        if (!mondaySpare && !mondayStrike) {
            monday = 0;
        } else if (mondaySpare && !mondayStrike) {
            monday = 1;
        } else if (mondayStrike) {
            monday = 2;
        }

        if (!tuesdaySpare && !tuesdayStrike) {
            tuesday = 0;
        } else if (tuesdaySpare && !tuesdayStrike) {
            tuesday = 1;
        } else if (tuesdayStrike) {
            tuesday = 2;
        }

        if (!wednesdaySpare && !wednesdayStrike) {
            wednesday = 0;
        } else if (wednesdaySpare && !wednesdayStrike) {
            wednesday = 1;
        } else if (wednesdayStrike) {
            wednesday = 2;
        }

        if (!thursdaySpare && !thursdayStrike) {
            thursday = 0;
        } else if (thursdaySpare && !thursdayStrike) {
            thursday = 1;
        } else if (thursdayStrike) {
            thursday = 2;
        }

        if (!fridaySpare && !fridayStrike) {
            friday = 0;
        } else if (fridaySpare && !fridayStrike) {
            friday = 1;
        } else if (fridayStrike) {
            friday = 2;
        }

        if (!saturdaySpare && !saturdayStrike) {
            saturday = 0;
        } else if (saturdaySpare && !saturdayStrike) {
            saturday = 1;
        } else if (saturdayStrike) {
            saturday = 2;
        }

        if (!sundaySpare && !sundayStrike) {
            sunday = 0;
        } else if (sundaySpare && !sundayStrike) {
            sunday = 1;
        } else if (sundayStrike) {
            sunday = 2;
        }

        var clientUrl = "/Strike/SaveStrike/";

        var week = `${monday}${tuesday}${wednesday}${thursday}${friday}${saturday}${sunday}`;
        // Simulate fetching a week's data, e.g., "210" for Monday=2, Tuesday=1, Wednesday=0, etc.
        await fetchPost<void, string>(clientUrl, week); // Assume this function fetches the week's training data
    }
</script>

<div class="heading">
    <div class="content heading-1 now">Monday</div>
    <div class="content heading-1">Tuesday</div>
    <div class="content heading-1">Wednesday</div>
    <div class="content heading-1">Thursday</div>
    <div class="content heading-1">Friday</div>
    <div class="content heading-1">Saturday</div>
    <div class="content heading-1">Sunday</div>

    <!-- 7 spare first -->

    <div class="content points now">
        <input type="checkbox" id="mondaySpare" bind:checked={mondaySpare} on:change={updateDayValue} />
        <button>spare</button>
    </div>

    <div class="content points">
        <input type="checkbox" id="tuesdaySpare" bind:checked={tuesdaySpare} on:change={updateDayValue} />
        <button>spare</button>
    </div>

    <div class="content points">
        <input type="checkbox" id="wednesdaySpare" bind:checked={wednesdaySpare} on:change={updateDayValue} />
        <button>spare</button>
    </div>

    <div class="content points">
        <input type="checkbox" id="thursdaySpare" bind:checked={thursdaySpare} on:change={updateDayValue} />
        <button>spare</button>
    </div>

    <div class="content points">
        <input type="checkbox" id="fridaySpare" bind:checked={fridaySpare} on:change={updateDayValue} />
        <button>spare</button>
    </div>

    <div class="content points">
        <input type="checkbox" id="saturdaySpare" bind:checked={saturdaySpare} on:change={updateDayValue} />
        <button>spare</button>
    </div>

    <div class="content points">
        <input type="checkbox" id="sundaySpare" bind:checked={sundaySpare} on:change={updateDayValue} />
        <button>spare</button>
    </div>

    <!-- 7 strikes -->

    <div class="content points now">
        <input
            type="checkbox"
            id="mondayStrike"
            bind:checked={mondayStrike}
            on:change={updateDayValue}
            disabled={!mondaySpare}
        />
        <button>strike</button>
    </div>

    <div class="content points">
        <input
            type="checkbox"
            id="tuesdayStrike"
            bind:checked={tuesdayStrike}
            on:change={updateDayValue}
            disabled={!tuesdaySpare}
        />
        <button>strike</button>
    </div>

    <div class="content points">
        <input
            type="checkbox"
            id="wednesdayStrike"
            bind:checked={wednesdayStrike}
            on:change={updateDayValue}
            disabled={!wednesdaySpare}
        />
        <button>strike</button>
    </div>
    <div class="content points">
        <input
            type="checkbox"
            id="thursdayStrike"
            bind:checked={thursdayStrike}
            on:change={updateDayValue}
            disabled={!thursdaySpare}
        />
        <button>strike</button>
    </div>
    <div class="content points">
        <input
            type="checkbox"
            id="fridayStrike"
            bind:checked={fridayStrike}
            on:change={updateDayValue}
            disabled={!fridaySpare}
        />
        <button>strike</button>
    </div>
    <div class="content points">
        <input
            type="checkbox"
            id="saturdayStrike"
            bind:checked={saturdayStrike}
            on:change={updateDayValue}
            disabled={!saturdaySpare}
        />
        <button>strike</button>
    </div>
    <div class="content points">
        <input
            type="checkbox"
            id="sundayStrike"
            bind:checked={sundayStrike}
            on:change={updateDayValue}
            disabled={!sundaySpare}
        />
        <button>strike</button>
    </div>
</div>

<style>
    .heading {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto auto;
        grid-template-rows: repeat(30, auto);
        flex-direction: column;
        text-align: center;
        left: 50px;
        top: 80px;
        color: white;
        background: black;
        border: 1px solid white;
    }
    .heading-1 {
        background: rgb(0, 111, 201);
    }
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        border: 1px solid white;
    }

    button {
        background: rgb(111, 190, 255);
        width: 50px;
        height: 20px;
        font-size: 12px;
    }
    .now {
        background-color: rgba(137, 255, 245, 0.306);
    }
</style>
