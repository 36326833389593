<script lang="ts">
  import { fetchGet, fetchPost } from "./services/fetch";

  async function runMethod() {
    alert("Running Admin Method");

    var clientUrl = "/Admin/RunAdminMethod/";
    await fetchGet<void>(clientUrl);

    clientUrl = "/Crammer/ForgetLesson/";
    await fetchGet<void>(clientUrl);
    location.reload();

    alert("Finished Running Admin Method");
  }
</script>

<div>
  <button on:click={runMethod}> Run Admin Method </button>
</div>
