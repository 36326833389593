import { toast } from '@zerodevx/svelte-toast'


export class Errorhandler {

    public static HandleError(data: any): boolean {
        let errorhandler = new Errorhandler();

        let result = errorhandler.handleError(data);
        return result;
    }

    public handleError(data: any): boolean {
        if (data.ok == false) {

            //Not awaitable, so the toast might be shown after something else has happened
            const errorMessage = data.json().then((errorMessage: any) => {
                if (errorMessage.error) {
                    toast.push(errorMessage.error, { classes: ['error'], pausable: true });
                    console.log(errorMessage.error);
                }
            });
            return true;
        }


        else if (data.statusText && data.statusText != 'OK') {
            toast.push(data.statusText, { classes: ['error'], pausable: true });
            console.log(data.Message);
            return true;
        }
        else if (data.Message) {
            toast.push(data.Message, { classes: ['error'], pausable: true });
            console.log(data.Message);
            return true;
        }
        else
            return false;
        return false;
    }
}