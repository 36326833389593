<script lang="ts">
	import { Route } from 'svelte-routing';
	import PrivateRouteGuard from './PrivateRouteGuard.svelte';
  
	export let path: string;
	export let component: any; // e.g. the Keypad component
  
	//console.log("Private route path " + path);
  </script>
  
  <Route {path} let:params>
	<PrivateRouteGuard>
	  <svelte:component this={component} {...params} />
	</PrivateRouteGuard>
  </Route>
  