<script lang="ts">
import { createEventDispatcher, onMount } from "svelte";
import { drag } from "d3-drag";
import { select } from "d3-selection";

    export let letter: { id: number; value: string };
    export let x: number;
    export let y: number;

    let offsetX: number, offsetY: number;

    const dispatch = createEventDispatcher();

    function escapeCssId(id: string): string {
        return id.replace(/([^\w-])/g, "\\$1");
    }

    onMount(() => {
        const elementId = `letter-${letter.id}-${letter.value}`;
        const escapedElementId = escapeCssId(elementId);
        const draggable: any = select(`#${escapedElementId}`);

        //console.log("elementId " + elementId);
        //console.log("escapedElementId " + escapedElementId);
        //console.log("draggable " + draggable);

        draggable.call(
            drag<SVGGElement, unknown>()
                .on("start", (event: any) => {
                    // Calculate the initial offset
                    offsetX = event.x - x;
                    offsetY = event.y - y;
                })
                .on("drag", (event: any) => {
                    // Apply the offset to the new position
                    x = event.x - offsetX;
                    y = event.y - offsetY;
                })
                .on("end", (event: any) => {
                    dispatch("dragend", { letter, finalX: event.x });
                }),
        );
    });
</script>

DL

<g transform={`translate(${x}, ${y})`} id={`letter-${letter.id}-${letter.value}`}>
    <text x="0" y="0" fill="white"  style="font-size: 30px; font-family: 'Roboto Mono', monospace; font-weight: bold;">
        {letter.value}
    </text>
</g>
