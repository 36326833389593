<script lang="ts">
    import Achievement from "./Achievement.svelte";
    import { isMobile, isLoading, newEvent } from "./services/stores";
    console.log("accomplished:" + $newEvent);

    let title = "Mission accomplished";
    let text = "";

    if ("solve-1-rated-problem" == $newEvent) text = "Congrats you solved a rated problem!";
    if ("solve-10-rated-problems" == $newEvent) text = "Congrats you solved 10 rated problems!";
    if ("solve-100-rated-problem" == $newEvent) text = "Congrats you solved 100 rated problems!";
    if ("reach-a-rating-of-100" == $newEvent) text = "Congrats you reached 100 rated problems!";
    if ("reach-a-rating-of-200" == $newEvent) text = "Congrats you reached 200 rated problems!";
    if ("reach-a-rating-of-300" == $newEvent) text = "Congrats you reached 300 rated problems!";
    if ("reach-a-rating-of-400" == $newEvent) text = "Congrats you reached 400 rated problems!";
    if ("reach-a-rating-of-500" == $newEvent) text = "Congrats you reached 500 rated problems!";
    if ("reach-a-rating-of-1000" == $newEvent) text = "Congrats you reached 1000 rated problems!";
    if ("reach-a-rating-of-1100" == $newEvent) text = "Congrats you reached 1100 rated problems!";
    if ("reach-a-rating-of-1200" == $newEvent) text = "Congrats you reached 1200 rated problems!";
    if ("reach-a-rating-of-1300" == $newEvent) text = "Congrats you reached 1300 rated problems!";
    if ("reach-a-rating-of-1400" == $newEvent) text = "Congrats you reached 1400 rated problems!";
    if ("reach-a-rating-of-1500" == $newEvent) text = "Congrats you reached 1500 rated problems!";
    if ("reach-a-rating-of-1600" == $newEvent) text = "Congrats you reached 1600 rated problems!";
    if ("reach-a-rating-of-1700" == $newEvent) text = "Congrats you reached 1700 rated problems!";
    if ("reach-a-rating-of-1800" == $newEvent) text = "Congrats you reached 1800 rated problems!";
    if ("reach-a-rating-of-1900" == $newEvent) text = "Congrats you reached 1900 rated problems!";
    if ("reach-a-rating-of-2000" == $newEvent) text = "Congrats you reached 2000 rated problems!";
    if ("reach-the-top-2-percent" == $newEvent) text = "Congrats you reached the top 2 percent problems!";
    if ("reach-the-top-10-percent" == $newEvent) text = "Congrats you reached the top 10 percent problems!";
</script>

<div class="accomplished">
    <!-- <button data-test="close-button" class="close-button">
        <img src="https://d35aaqx5ub95lt.cloudfront.net/images/ed25a8cf69261b0c1e25b147f369f74a.svg" alt="Close button" />
        <span class="tooltip-text">Close</span>
    </button> -->
    <Achievement {title} colorval="gold" {text}></Achievement>
</div>

<style>
    .accomplished {
        display: flex;
    }
    /* .close-button {
        cursor: pointer;
        z-index: 10;
        background: none;
        border: none;
        position: relative;
        width: 60px;
    } */

    /* .tooltip-text {
        visibility: hidden;
        width: 60px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        top: 115%;
        left: 50%;
        margin-left: -35px;
        opacity: 0;
        transition: opacity 0.3s;
    }

    .close-button:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    } */
</style>
