<script lang="ts">
    import { navigate } from "svelte-routing";
    import Result from "./Result.svelte";
    import { type ResultInterface } from "./ResultInterface";

    export let results: ResultInterface[]; //used for the results

    function goHome() {
        console.log("Going home");
    }
    function goAgain() {
        location.reload();
    }
    let showResults = false;

    function toggleResultHighScore() {
        showResults = !showResults;
    }

    //let showResult = true;

    function toggleResultHiscore() {
        showResults = !showResults;
    }
</script>

<!-- <Result></Result> -->
<div class="go-container">
    <div class="gameover">
        <h1>Next time!</h1>
        <button on:click={toggleResultHighScore} class="result-button">Result</button>
    </div>
    <div class="scorepage-button">
        <button on:click={goAgain} class="button-scorepage"
            ><img alt="redo" src="/redo.svg" style="" width="30px" height="30px" /></button
        >
        <!-- <button on:click={() => navigate("/home")} class="button-scorepage">
            <img alt="himeicon" src="/homeicon.svg" style="" width="30px" height="30px" /></button
        > -->
    </div>

    {#if showResults}
        <Result {results}></Result>
    {:else}
        <!-- <HiScore {slug}></HiScore> -->
    {/if}
</div>

<style>
    .go-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.806);
        justify-content: center;
        align-items: center;
    }
    h1 {
        padding: 10px;
    }
    .result-button {
        background: none;
        border: 1px solid yellow;
        font-size: 17px;
        width: 65px;
        color: white;
    }
    .gameover {
        display: flex;
        position: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        margin: 20px;
        width: 430px;
        height: 300px;
        padding: 20px;
        text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.3);
        background-color: #000;
        color: white;
        border: 5px solid yellow;
        padding-bottom: 20px;
        border-radius: 20px;
    }
    .scorepage-button {
        padding: 20px;
        display: inline-block;
    }
</style>
