<link href="https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap" rel="stylesheet">

<script lang="ts">
	import DraggableLetter from "./DraggableLetter.svelte";

    let phrase = "Welcome to Plezuz!";
    let letters = phrase.split('').map((letter, index) => {
        return { id: index, value: letter };
    });

    let start = 70; 
    let diff = 20;  
    let initialPositions :any = [];

    for (let i = 0; i < letters.length; i++) {
        initialPositions.push({ x: start + i * diff, y: 100 });
    }
	function handleDragEnd(event:any) {
		const { letter, finalX } = event.detail;

		let newIndex = Math.round((finalX - start) / diff);
		newIndex = Math.max(0, Math.min(newIndex, letters.length - 1));

		// Use the id to find the correct index
		const oldIndex = letters.findIndex((l) => l.id === letter.id);
		if (oldIndex !== -1) {
			letters.splice(oldIndex, 1);
			letters.splice(newIndex, 0, letter);
		}

		// Update the ids to reflect the new order
		letters = letters.map((item, index) => ({ ...item, id: index }));
	}
</script>

<svg width="500" height="200">
	{#each letters as letter (letter.id)}
		<DraggableLetter
			{letter}
			x={initialPositions[letter.id].x}
			y={initialPositions[letter.id].y}
			on:dragend={handleDragEnd} 
		/>
	{/each}
</svg>
