<script lang="ts">
    import { type ResultInterface } from "./ResultInterface";
    export let results: ResultInterface[];
</script>

<div class="list">
    <div class="content heading">Question</div>
    <div class="content heading">Answer</div>
    <div class="content heading">Your Answer</div>
    <div class="content heading">Time Spent</div>
    <div class="content heading">Points</div>
    {#each results as result}
        <div class="content">{result.Question}</div>
        <div class="content">{result.Answer}</div>
        <div class="content" class:wrong={!result.IsCorrect}>{result.YourAnswer}</div>
        <div class="content">{result.TimeSpent.toFixed(2)}</div>
        <div class="content">{result.Points}</div>
    {/each}
</div>

<style>
    .wrong {
        background-color: rgba(255, 0, 0, 0.514);
    }

    .list {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        gap: 5px;
        /* position: absolute; */
        flex-direction: column;
        text-align: center;
        left: 50px;
        top: 80px;
        color: white;
        background: black;
        width: 570px;
        z-index: 7;
        border: 1px solid rgb(255, 234, 0);
    }
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        border: 1px solid rgba(255, 217, 0, 0.612);
    }
    .heading {
        background: rgb(255, 234, 0);
        color: black;
        font-weight: bold;
    }
</style>
